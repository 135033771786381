<!--

  Confirm that the user creates a record within the created period and company.

-->
<template>
  <div class="container">

    <b-modal
      :title="$t('PERIOD.SELECT_PERIOD_AND_COMPANY')"
      ref="edit-form"
      hide-footer
      >

      <b-form ref="form" @submit.prevent="on_submit" class="mt-8">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('PERIOD.PERIOD')">

                <b-form-select
                  v-model="item.period_id"
                  :options="period_options"
                  ></b-form-select>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('COMPANY.COMPANY')">

                <b-form-select

                  v-model="item.company_id" :options="company_options">
                </b-form-select>

            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RightModalSaveAndCloseButtons
              :text="$t('COMMON.SAVE')"
              :spin="true"
              @clicked="on_submit"
              @close="$refs['edit-form'].hide()"
              ref="saveButton"
            />
          </b-col>
        </b-row>
      </b-form>

    </b-modal>


  </div>

</template>


<script>

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import { mapGetters } from 'vuex';
import store from '@/core/services/store';


import {
  SET_COMPANY_ID,
  SET_PERIOD_ID
} from '@/core/services/store/common.module';

export default {

  name: 'SelectPeriodAndCompanyModal',

  props: ['member','items','company','creating_member'],

  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons
  },

  watch: {
    currentCompanyId() {
      this.item.company_id = this.currentCompanyId;
    },
    currentPeriodId() {
      this.item.period_id = this.currentPeriodId;
    }
  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),
    is_mobile() {
      return is_mobile();
    },
    period_options() {
      return this.periods.map(( item ) => ( { text: item.name, value: item.id }));
    },
    company_options() {
      if (!this.companies) {
        return [];
      }

      return this.companies.map((item) => ({ text: item.name, value: item.id }));
    },
  },

  methods: {
    show() {
      this.$refs['edit-form'].show();
    },


    on_submit() {
      if (this.currentCompanyId !== this.item.company_id) {
        store.dispatch(SET_COMPANY_ID, this.item.company_id);
      }

      if (this.currentPeriodId !== this.item.period_id) {
        store.dispatch(SET_PERIOD_ID, this.item.period_id);
      }

      this.$refs['edit-form'].hide();
    }
  },

  mounted() {

    this.item.period_id = this.currentPeriodId;
    this.item.company_id = this.currentCompanyId;
  },

  data() {
    return {
      item: {
        period_id: null,
        company_id: null
      }
    };
  }
};

</script>

<style lang="scss" scoped>
  @import "@/assets/sass/components/forms/_memlist_table.scss";
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
