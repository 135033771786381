<template>
  <div id="page-member-control">


    <v-tabs background-color="white" color="accent-4" left>
      <v-tab>{{ $t('MEMBER.MEMBERS') }}</v-tab>
      <v-tab>{{ $t('MEMBER.MEMBERS_PURGE') }}</v-tab>
      <v-tab>{{ $t('PAGES.SETTINGS.MEMBER_REQUIREMENTS') }}</v-tab>
      <v-tab>{{ $t('PAGES.SETTINGS.MEMBER_TYPES') }}</v-tab>
      <v-tab>{{ $t('PAGES.SETTINGS.MEMBER_PROFILE') }}</v-tab>
      <v-tab v-if="isTHS">{{ $t('COMMON.SETTINGS') }}</v-tab>

      <v-tab-item class="pt-8">
        <MembersControlMembersTab 
          :member_id="member_id"
        />

      </v-tab-item>

      <v-tab-item class="pt-8">

        <MembersControlPurgeTab />
        
      </v-tab-item>

      
      <v-tab-item class="pt-8">
        <MembersControlRequirementsTab

        />
      </v-tab-item>

      <v-tab-item class="pt-8">
        <MemberTypesTab

        />
      </v-tab-item>

      <v-tab-item class="pt-8">
        <MembersControlMemberProfileSettingsTab 
        
        />
      </v-tab-item>

      <v-tab-item class="pt-8" v-if="isTHS">
        <MembersControlSettingsTab 

        />
      </v-tab-item>

    </v-tabs>

    <div id="wiztr-bottom"></div>

  </div>
</template>

<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';

// Edit component

import MembersControlMembersTab from '@/view/pages/ml/members_control/MembersControlMembersTab.vue';
import MembersControlSettingsTab from '@/view/pages/ml/members_control/MembersControlSettingsTab.vue';
import MembersControlRequirementsTab from '@/view/pages/ml/members_control/MembersControlRequirementsTab.vue';
import MembersControlPurgeTab from '@/view/pages/ml/members_control/MembersControlPurgeTab.vue';
import MembersControlMemberProfileSettingsTab from '@/view/pages/ml/members_control/MembersControlMemberProfileSettingsTab.vue';
import MemberTypesTab from '@/view/pages/ml/members_control/MemberTypesTab.vue';


export default {
  name: 'member-control-page',
  mixins: [ toasts ],
  components: {
    MembersControlMembersTab,
    MembersControlSettingsTab,
    MembersControlRequirementsTab,
    MembersControlPurgeTab,
    MembersControlMemberProfileSettingsTab,
    MemberTypesTab
  },
  beforeRouteUpdate(to, from, next) {
    this.member_id = to.params.member_id;
    next()
  },
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'isTHS'])
  },
  mounted() {
    //this.example = this.examples[0];
    // get member id from route
    
    this.member_id = this.$route.params.member_id;
  },
  data() {
    return {
      member_id: null
    };
  },
  watch: {
    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
    }

  },
  methods: {
    member_created(member) {},
    member_updated(member) {},
    member_deleted(member_id) {}
  }
};
</script>
