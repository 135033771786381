<template>
  <div id="member-control-types-tab">

    <MemberTypesEditorModal 
      :item="selected_item"
      ref="member-types-editor-modal"
      @created="created_membertype"
      @updated="updated_membertype"
    />    

    <MemberTypesTable 
      :items="items"
      @select="select_clicked"
      @create="create_clicked"
      @delete="delete_clicked"
    />

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import MemberTypesEditorModal from './MemberTypesEditorModal.vue';
import MemberTypesTable from './MemberTypesTable.vue';


export default {
  name: 'MemberTypesTab',
  components: {
    MemberTypesEditorModal,
    MemberTypesTable
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'sid']),
  },
  props: [],
  async mounted() {
    await this.load_membertypes();
  },
  methods: {

    created_membertype(item) {
      this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBERTYPE.CREATED'));

      this.items.push({ ...item });
    },

    updated_membertype(item) {

      const index = this.items.findIndex(x => x.id === item.id);

      if (index !== -1) {
        this.items[index] = { ...item };
      }

      this.items = [...this.items];

      this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBERTYPE.UPDATED'));
    },

    select_clicked(item) {
      this.selected_item = { ...item };
      this.$refs['member-types-editor-modal'].show();
    },

    create_clicked() {
      this.selected_item = {};
      this.$refs['member-types-editor-modal'].show();
    },

    async delete_clicked(item) {
      await this.delete_membertype(item);

      this.items = this.items.filter(x => x.id !== item.id);

      this.items = [...this.items];
    },

    async delete_membertype(item) {
      try {
        const result = await axios.delete(`/membertype/${item.id}`);

        if (result.status === 204) {
          this.load_membertypes();
        }
      }
      catch (err) {
        console.error('delete_membertype error', err);
      }
    },

    
    async load_membertypes() {
      try {
        const result = await axios.get(`/membertype`);

        if (result.status === 200) {
          this.items = result.data;
        }
      }
      catch (err) {
        console.error('load_settings error', err);
      }
    },


  },
  data() {
    return {

      items: [],
      selected_item: null

    };
  },
  watch: {

  },

};
</script>
<style lang="scss" scoped>

:deep .v-treeview-node__root:hover {
  cursor: pointer;
}

.tree-panel {
  background-color: white;
}

:deep .v-simple-checkbox {
  float: left;
  width: 22px;
  margin-left: 8px;
}

:deep .v-input__slot label {
  font-size: 12px;
}

:deep .v-data-table__wrapper tr:first-child {
  height: 66px;
  background-color: rgb(239, 239, 239) !important;
}

</style>
