import { render, staticRenderFns } from "./MembersControlPurgeTab.vue?vue&type=template&id=37f2b3e7&scoped=true"
import script from "./MembersControlPurgeTab.vue?vue&type=script&lang=js"
export * from "./MembersControlPurgeTab.vue?vue&type=script&lang=js"
import style0 from "./MembersControlPurgeTab.vue?vue&type=style&index=0&id=37f2b3e7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37f2b3e7",
  null
  
)

export default component.exports