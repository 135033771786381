<template>
  <div id="member-types-editor-modal">

    <b-modal ref="edit-membertypes-modal" hide-footer>
      
      <form @submit.prevent="submit_form">

        <b-row>
          <b-col md="6" sm="12">
            <b-form-group :label="$t('COMMON.NAME')">
              <b-form-input v-model="local_item.name" type="text" :placeholder="$t('COMMON.NAME')"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>


        <RightModalSaveAndCloseButtons
          :text="$t('COMMON.SAVE')"
          :spin="true"
          @clicked="submit_form"
          @close="on_cancel"
          ref="saveButton"
        />

      </form>

    </b-modal>

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';

export default {
  name: 'MemberTypesEditorModal',
  components: {
    RightModalSaveAndCloseButtons
  },
  mixins: [ toasts ],
  
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
    
  },
  props: ['item'],
  emits: ['created', 'updated'],
  async mounted() {
    if (this.item) {
      this.local_item = { ...this.item }
    }
  },
  methods: {

    show() {
      this.$refs['edit-membertypes-modal'].show();
    },

    on_cancel() {
      this.$refs['edit-membertypes-modal'].hide();
    },
    
    async submit_form() {
      if (this.local_item.id) {
        await this.update_membertype(this.local_item);

        this.$emit('updated', this.local_item);
      }
      else {
        await this.create_membertype(this.local_item);

        this.$emit('created', this.local_item);
      }

      this.$refs['saveButton'].stop();

      this.$refs['edit-membertypes-modal'].hide();


    },

    async create_membertype(item) {
      try {
        const result = await axios.post(`/membertype`, {
          name: item.name
        });

        this.$refs['saveButton'].stop();

        if (result.status === 201) {
          return result.data;
        }
      }
      catch (err) {
        console.error('create_membertype error', err);

        this.$refs['saveButton'].stop();
      }

      
    },

    async update_membertype(item) {
      try {
        const result = await axios.put(`/membertype/${item.id}`, {
          name: item.name
        });

        this.$refs['saveButton'].stop();

        if (result.status === 204) {
          return true;
        }
      }
      catch (err) {
        console.error('update_membertype error', err);
      }

      return false;
    },


  },
  data() {
    return {
      local_item: {
        
      },
    };
  },
  watch: {
    item: {
      handler: function (val) {
        this.local_item = { ...val };
      },
      deep: true
    }
  },

};
</script>
<style lang="scss" scoped>

:deep .v-treeview-node__root:hover {
  cursor: pointer;
}

.tree-panel {
  background-color: white;
}

:deep .v-simple-checkbox {
  float: left;
  width: 22px;
  margin-left: 8px;
}

:deep .v-input__slot label {
  font-size: 12px;
}

:deep .v-data-table__wrapper tr:first-child {
  height: 66px;
  background-color: rgb(239, 239, 239) !important;
}

</style>
