<template>
  <div id="member-control-member-profile-tab">

    <MemberProfileSettings
      v-if="company"
      :company="company"
    />

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import MemberProfileSettings from '../settings/MemberProfileSettings.vue';

export default {
  name: 'member-control-member-profile-tab',
  components: {
    MemberProfileSettings
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'sid']),
  },
  props: [],
  async mounted() {
    await this.load_settings();
  },
  methods: {


    async load_settings() {
      try {
        const result = await axios.get(`/company/${this.sid}`);

        if (result.status === 200) {
          this.company = result.data;
        }
      }
      catch (err) {
        console.error('load_settings error', err);
      }
    },

    async save_settings() {
      try {
        const result = await axios.put(`/company/company_settings/${this.sid}`, {});

        if (result.status !== 200) {
          console.error('save_settings error', result);
        }

      }
      catch (err) {
        console.error('load_settings error', err);
      }
    }


  },
  data() {
    return {

      company: null

    };
  },
  watch: {

  },

};
</script>
<style lang="scss" scoped>

:deep .v-treeview-node__root:hover {
  cursor: pointer;
}

.tree-panel {
  background-color: white;
}

:deep .v-simple-checkbox {
  float: left;
  width: 22px;
  margin-left: 8px;
}

:deep .v-input__slot label {
  font-size: 12px;
}

:deep .v-data-table__wrapper tr:first-child {
  height: 66px;
  background-color: rgb(239, 239, 239) !important;
}

</style>
