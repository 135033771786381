<template>
  <div id="group-editor-permissions">

    <h3 class="ml-2" style="color: red;">{{ $t('SYSTEM.ONLY_THS') }}</h3>

    <ExpandableTable
      :children="shown_settings_data"
      :fields="shown_settings_fields"
      :indent_colors="['#eff4f9','#ffffff', '#ffffff', '#ffffff']"
      highlightable

      @content_link_clicked="content_link_clicked"
      @cell_value_changed="cell_checkbox_changed"
    />

  </div>
</template>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { getPageNames } from '@/core/services/pagenames';
import ExpandableTable from '@/view/components/tables/ExpandableTable.vue';

export default {
  name: 'MemberControlSettingsTab',
  components: {
    ExpandableTable
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'sid']),
    shown_settings_fields() {
      return [
        { name: 'translated', text: this.$t('MEMBER_EDITOR.SECTION_FIELD'), cols: 6 },
        { name: 'value', text: this.$t('MEMBER_EDITOR.SHOW'), cols: 2, type: 'checkbox' },
      ];
    },

  },
  props: [],
  async mounted() {
    await this.load_settings();
  },
  methods: {

    content_link_clicked() {},

    find_node_with_row_number(row_number) {
      let row = 0;

      for (let i = 0; i < this.shown_settings_data.length; i++) {

        if (row === row_number) {
          return this.shown_settings_data[i];
        }

        row++;

        for (let j = 0; j < this.shown_settings_data[i].children.length; j++) {

          if (row === row_number) {
            return this.shown_settings_data[i].children[j];
          }

          row++;
        }

      }

      return null;
    },

    async cell_checkbox_changed(value) {

      const node = this.find_node_with_row_number(value.row);

      if (node === null) {
        console.error('unable to find row', node);
      }

      node.value = value.value;

      this.shown_settings_data = [...this.shown_settings_data];

      await this.save_settings();
    },

    async load_settings() {
      try {
        const result = await axios.get(`/company/${this.sid}`);

        if (result.status === 200) {
          this.shown_settings_data = result.data.company_settings.member_editor.editor_unlocks;

          for (const r of this.shown_settings_data) {
            r.translated = this.$t(`MEMBER_EDITOR.${r.name.toUpperCase()}`);

            if (r.children) {
              for (const c of r.children) {
                c.translated = this.$t(`MEMBER.${c.name.toUpperCase()}`);
              }
            }
          }
        }
      }
      catch (err) {
        console.error('load_settings error', err);
      }
    },

    async save_settings() {
      try {
        const result = await axios.put(`/company/company_settings/${this.sid}`, {
          member_editor: {
            editor_unlocks: this.shown_settings_data
          }
        });

        if (result.status !== 200) {
          console.error('save_settings error', result);
        }

      }
      catch (err) {
        console.error('load_settings error', err);
      }
    }


  },
  data() {
    return {

      shown_settings_data: []

    };
  },
  watch: {

  },

};
</script>
<style lang="scss" scoped>

:deep .v-treeview-node__root:hover {
  cursor: pointer;
}

.tree-panel {
  background-color: white;
}

:deep .v-simple-checkbox {
  float: left;
  width: 22px;
  margin-left: 8px;
}

:deep .v-input__slot label {
  font-size: 12px;
}

:deep .v-data-table__wrapper tr:first-child {
  height: 66px;
  background-color: rgb(239, 239, 239) !important;
}

</style>
