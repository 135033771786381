<template>

  <div class="ml-2">
    <Confirm
      :title="$t('MEMBER.DELETE_ALL')"
      :message="$t('MEMBER.CONFIRM_DELETE_ALL')"
      :confirm_with_text="$t('MEMBER.DELETE_STRING')"
      variant="text-danger"
      ref="confirm_delete_all"
      @confirm="confirmed_delete_all"
    />

    <Confirm
      :title="$t('MEMBER.DELETE_SELECTED')"
      :message="$t('MEMBER.CONFIRM_DELETE_SELECTED', { count: selected_member_ids.length })"
      ref="confirm_delete_selected"
      @confirm="confirmed_delete_selected"
    />


    <MemberEditorCompactModal
      ref="member-editor-compact-modal-tab"
      class="mt-8"
      :member_id="member_id"

      @created="member_created"
      @updated="member_updated"
      @deleted="member_deleted"
    />

    <b-row>
      <b-col cols="10">

        <b-row>
          <b-col cols="4">

            <b-form-group :label="$t('PAGES.RENEWS.CREATED_FROM')">
              <memlist-date-picker v-model="filters.created_from"></memlist-date-picker>
            </b-form-group>

          </b-col>
          <b-col cols="4">
            <b-form-group :label="$t('PAGES.RENEWS.CREATED_TO')">
              <memlist-date-picker v-model="filters.created_to"></memlist-date-picker>
            </b-form-group>
          </b-col>
        </b-row>


      </b-col>
    
      <b-col cols="2">

        <div class="d-flex align-items-end flex-column">
          <a
            v-if="isTHS"
            href="#"
            class="btn btn-danger font-weight-bolder font-size-sm mr-2 my-2"
            @click.prevent="delete_all_members_clicked"
            ><i class="menu-icon flaticon2-trash" style="font-size: 1.0em;"></i>{{$t('MEMBER.DELETE_ALL')}}</a
          >
          <a
            href="#"
            class="btn btn-outline-danger font-weight-bolder font-size-sm mr-2 my-2"
            @click.prevent="delete_selected_members_clicked"
            ><i class="menu-icon flaticon2-trash" style="font-size: 1.0em;"></i>{{$t('MEMBER.DELETE_SELECTED')}}</a
          >
        </div>

      </b-col>

    </b-row>

    <b-row>
      <b-col cols="6" >
        <div class="bottom-alignment">
          <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{total_rows}}
        </div>

      </b-col>
      <b-col cols="6">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[100, 250]"
            v-model="per_page"
          />
          <b-pagination
            class="mb-0 mt-2 align-bottom"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            aria-controls="member-table"
            first-number
            last-number
          ></b-pagination>
        </div>
      </b-col>
    </b-row>

    <b-table
      id="member-table"
      style="width: 100%; table-layout: fixed;"
      class="table-striped"
      :fields="fields"
      :filter="filters"
      :items="ajax_pagination"
      :per-page="per_page"
      :current-page="current_page"
      head-variant="light"
      ref="memberTable"
      @filtered="onFiltered"
      @sort-changed="onSortChanged"
    >

    <template #head(selected)="row">
      <v-checkbox
        v-model="all_selected"
        style="margin-top: 0px; padding-top: 0px;"
      ></v-checkbox>
    </template>
    <template #cell(selected)="row">
      <v-checkbox
        style="margin-top: 0px; padding-top: 0px;"
        v-model="row.item.selected"
        @change="row_selected(row.item.selected, row.item.id)"
      ></v-checkbox>
    </template>
    <template #cell(actions)="row">
      <div class="justify-content-end d-flex">
        <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="select_member_clicked(row.item.id)">
          <span class="svg-icon svg-icon-md text-primary">
            <i class="fa fa-user color-primary"></i>
          </span>
        </a>

      </div>
    </template>

  </b-table>

  <b-row class="m-4">
    <b-col><span>{{ $t('COMMON.NUM_ROWS') }}: {{total_rows}}</span></b-col>
  </b-row>

  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import axios from 'axios';
import dayjs from 'dayjs';

import { mapGetters } from 'vuex';
import isBetween from 'dayjs/plugin/isBetween';
import { is_mobile } from '@/core/services/utils';
import MemberEditorCompactModal from '@/view/components/member_editor_v2/MemberEditorCompactModal.vue';
import Confirm from '@/view/components/Confirm.vue';

dayjs.extend(isBetween);

export default {
  name: 'member-control-purge-tab',
  mixins: [ toasts ],
  components: {
    Confirm,
    MemberEditorCompactModal
  },
  emits: ['filters_changed'],
  computed: {
    ...mapGetters(['currentPeriodId', 'periods', 'isTHS']),
    currentPeriod() {
      return this.periods.find(period => period.id === this.currentPeriodId);
    }
  },
  mounted() {

    this.fields = this.fields.filter(item => item.visible);
  },
  watch: {
    all_selected() {
      if (this.all_selected) {
        this.selected_member_ids = [];
        // Select all rows
        this.copy_rows.forEach(row => {
          row.selected = true;
          if (!this.selected_member_ids.includes(row.id)) {
            this.selected_member_ids.push(row.id);
          }
        });
      } else {
        // Deselect all rows
        this.copy_rows.forEach(row => {
          row.selected = false;
        });
        this.selected_member_ids = [];
      }
    },
    'filters.created_from'() {
      this.$emit('filters_changed', { created_from: this.filters.created_from, created_to: this.filters.created_to });
    },
    'filters.created_to'() {
      this.$emit('filters_changed', { created_from: this.filters.created_from, created_to: this.filters.created_to });
    },
    currentPeriod() {

    }
  },
  methods: {
    row_selected(selected, member_id) {
      if (selected) {
        // Add the member_id if not already in the array
        if (!this.selected_member_ids.includes(member_id)) {
          this.selected_member_ids.push(member_id);
        }
      } else {
        // Remove the member_id from the array if it exists
        this.selected_member_ids = this.selected_member_ids.filter(id => id !== member_id);
      }
    },

    member_deleted(member_id) {
      this.$refs['memberTable'].refresh();
    },

    delete_selected_members_clicked() {
      this.$refs['confirm_delete_selected'].show();
    },

    delete_all_members_clicked() {
      this.$refs['confirm_delete_all'].show();
    },

    async confirmed_delete_all() {
      console.log(' DELETE ALL!! ')

      try {
        const res = await axios.delete(`/member/all`);

        if (res.status === 204) {
          location.reload();
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_DELETE_MEMBERS'));
        }
      }
      catch (err) {
        console.error('confirmed_delete_all err', err);

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_DELETE_MEMBERS'));
      }
    },

    async confirmed_delete_selected() {
      // Use selected_member_ids for deletion
      const ids = this.selected_member_ids;

      if (ids.length === 0) {
          this.toastr('warning', this.$t('MEMBER.NO_MEMBERS_SELECTED'), this.$t('MEMBER.SELECT_MEMBERS_TO_DELETE'));
          return;
      }

      try {
        const loader = this.$loading.show();

        const res = await axios.post(`/member/delete`, { member_ids: ids });

        if (res.status === 200) {
          loader && loader.hide();

          this.toastr('success', this.$t('MEMBER.DELETED'), this.$t('MEMBER.MEMBERS_DELETED'));
          // Clear selected_member_ids after deletion
          this.selected_member_ids = [];
          // Reset selection in the table rows
          this.copy_rows.forEach(row => (row.selected = false));
          this.$refs['memberTable'].refresh();   
        }
      } catch (err) {
        loader && loader.hide();

        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_DELETE_MEMBERS'));
      }
    },

    select_member_clicked(member_id) {
      this.member_id = member_id;
      this.$refs['member-editor-compact-modal-tab'].show();
    },

    onSortChanged(sortChanged) {

      if (sortChanged.sortBy === '') {
        this.orderBy = undefined;
      }
      else {
        this.orderBy = {

        };

        this.orderBy[sortChanged.sortBy] = sortChanged.sortDesc ? 'desc' : 'asc';
      }
    },


    get_options() {

      const options = {};

      if (this.filters.created_from) {
        options.created_from = this.filters.created_from;
      }

      if (this.filters.created_to) {
        options.created_to = this.filters.created_to;
      }

      return options;
    },


    // items are fetched here whenever pagination ahas changed or
    // by forcing reload via: this.$refs['memberTable'].refresh();
    ajax_pagination: function(ctx, callback) {


      const vm = this;
      let params = {
        pageNo: ctx.current_page,
        pageSize: ctx.per_page,
        flag: 0,
        po: vm.searchOrderNumber,
        styNum: vm.searchStyleNumber
      };

      this.get_page_count();

      const options = this.get_options();

      axios
        .post(`/member/pagination`, {
          options: options,
          orderBy: this.orderBy,
          page: this.current_page,
          limit: this.per_page
        })
        .then(res => {
          var arr = res.data;

          this.all_selected = false;

          let i = 0;
          for (const a of arr) {
            a.index = i++;
            a.selected = this.copy_rows.findIndex(id => id === a.id) >= 0;
          }

          this.copy_rows = arr;

          callback(arr || []);
        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_GET_DATA'));
          console.error('pagination get error', err);
        });

      return null;
    },


    get_page_count() {

      const options = this.get_options();

      axios
        .post(`/member/pagination/count?limit=${this.per_page}&page=${this.current_page}`, { options: options })
        .then(res => {
          this.total_rows = res.data.count;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_GET_NUM_MEMBERS'));
        });
    },

    gotoMemberClicked(member_id) {
      this.$router.push(`/ml-member-editor/${member_id}`)
    },
    onRowSelected(items) {
      this.selected = items;
    },

    onFiltered(filteredItems) {
      this.filteredItems = filteredItems;
      this.allSelected = false;
    },

    get_all_selected_member_ids() {
      const ids = this.copy_rows.filter(item => item.selected).map(item => (item.id));

      return ids;
    },


  },
  data() {
    return {
      selected_member_ids: [],
      member_id: null,
      total_rows: 0,
      orderBy: { created_at: 'desc' },
      allSelected: false,
      can_member: false,
      current_page: 1,
      per_page: 100,
      options: [100, 150, 200],
      all_selected: null,
      copy_rows: [],
      filters: {
        created_from: null,
        created_to: null,
      },
      filteredItems: [],
      selected: [],
      fields: [


        {
          visible: true,
          key: 'selected',
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },

        {
          visible: is_mobile() ? false : true,
          key: 'created_at',
          label: this.$t('COMMON.CREATED_AT'),
          sortable: true,
          formatter: (_, __, item) => {
            return dayjs(item.created_at).format('YYYY-MM-DD');
          },
          sortByFormatted: true,
          tdClass: 'td-short4',
          thClass: 'td-short4'
        },

        {
          visible: true,
          key: 'public_id',
          label: this.$t('MEMBER.PUBLIC_ID'),
          sortable: true,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          visible: true,
          key: 'name',
          label: this.$t('MEMBER.NAME'),
          sortable: false,
          formatter: (_, __, item) => {
            return item.firstname + ' ' + item.lastname;
          },
          sortByFormatted: true,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },

        {
          visible: is_mobile() ? false : true,
          key: 'post',
          label: this.$t('MEMBER.POST'),
          sortable: false,
          sortByFormatted: true,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },

        {
          visible: is_mobile() ? false : true,
          key: 'email',
          label: this.$t('MEMBER.EMAIL'),
          sortable: false,
          sortByFormatted: true,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },

        {
          visible: is_mobile() ? false : true,
          key: 'phone',
          label: this.$t('MEMBER.PHONE'),
          sortable: false,
          sortByFormatted: true,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },

        {
          visible: true,
          key: 'actions',
          label: '',
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";



:deep .td-short {
  width: 32px;
}

:deep .td-short2 {
  width: 56px;
}

:deep .td-short3 {
  width: 76px;
}

:deep .td-short4 {
  width: 96px;
}

:deep .td-short5 {
  width: 116px;
}


:deep td {
  vertical-align: middle !important;
}

:deep .form-group {
  margin-bottom: 2px;
}


@media screen and (max-width: 768px) {
  #multiple-members-btn {
    margin-bottom: 2rem;
  }

  .date-time-picker {
    margin: 0;
  }

  .filter-box {
    margin-top: 1rem;
    padding: 0 !important;
  }
}
#status-filter {
  width: 200px;
}


.v-select .v-input__control .v-input__slot .v-select__slot .v-select__selections {
  padding: 0 !important;
  min-height: 0 !important;
}

.v-select-list .v-list-item {
  min-height: 0 !important;
}

.v-select-list .v-list-item .v-list-item__content {
  padding: 4px 0 !important;
}


  </style>
